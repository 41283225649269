<template>
  <div>
    <h1>ERROR</h1>
    <p class="title" style="">404</p>
    <p class="copyright">
      Copyright © 2021 todoen Inc. 保留所有权利<br />
      南京都享教育科技有限公司<br />
      商务合作&nbsp;&nbsp;&nbsp;15651708886
    </p>
  </div>
</template>
<script>
export default {
  name: "404"
};
</script>
<style lang="stylus">
h1{text-align: center;
    font-size: 103px;
    color: #a09e9e;
    margin-bottom: 2px;
    margin-top: 10%;}
.title{text-align: center;
    font-size: 25px;
    color: #a09e9e;}
#url_box_show_btn{color: #ccc;
    text-align: center;
    width: 90%;
    margin: 0 auto;
    margin-top: 5%;
    word-break: break-all; cursor: pointer}
#url_box{color: #ccc;
    text-align: center;
    width: 90%;
    margin: 0 auto;
    margin-top: 5%;
    word-break: break-all; display: none;}
.copyright{color: #ccc;
    text-align: center;
    width: 90%;
    word-break: break-all;
    position: fixed;
    bottom: 10%;
    font-size: 12px;
    left: 5%;}
</style>
